<template>
  <div class="wrapper">
    <div class="mock" @click="back"></div>
    <div class="title">
      <div class="text">用户协议和隐私政策</div>
      <div class="icon" @click="back">
        <img src="../assets/img/close.png">
      </div>
    </div>
    <div class="content">
      <div class="content_title">《用户协议和隐私政策》</div>
      <div class="content_text" :onscroll="onScroll" ref="scrollRef">
         <div>更新日期：2021年12月17日</div>
         <div>生效日期：2021年9月9日</div>
         <div>开发者：全购网络有限公司</div><br>
         <div text_title>欢迎使用由全购网络有限公司（简称“我们”或“全购网络”）提供服务或运营控制的“指点地球村”“全购任务”系列产品和服务，包括指点地球村（含工作台界面中由全购网络提供服务的应用程序）、一码通、云管理产品和服务（以下简称“本平台”）。<br>
            <br>本隐私政策载列了在您访问或使用本平台时，我们如何处理、收集、使用和披露您的个人信息。<br>
            <br>本隐私政策构成您与我们之间具法律约束力的协议，我们在此特别提醒您认真阅读、充分理解本协议各条款，特别是其中所涉及的免除、减轻我们责任的条款、对您权利限制条款、争议解决和法律适用等。其中，限制、免责条款可能以黑体加粗或加下划线的形式提示您重点注意。请您审慎阅读并选择接受或不接受本协议。若您不同意本隐私政策，请您停止访问或使用本平台。<br>
            <br>如果您是作为企业用户成员使用本平台，我们既会通过您的雇主（例如在您的雇主开设您的账户时）收集和处理个人信息，亦会直接向您（例如在您使用本平台的过程中）收集和处理个人信息。您的雇主在为了让您使用本平台而开设您的账户时，将向我们提供与您相关的若干个人信息，包括您的账户名、企业电邮、工作电话（如有）、工作地点信息以及您的上级主管。您的雇主可能向我们提供您的额外的个人信息，或在您使用本平台时更新向我们所提供的个人信息。<br>
            <br>如您是使用企业账户使用本平台，您理解并确认，为了让您更便捷地使用本平台，您的雇主已向我们提供与您相关的若干个人信息，包括您的账户名、企业电邮、工作电话（如有）、工作地点信息以及您的上级主管。根据您雇主的陈述和保证，您的雇主向我们提供您的上述个人信息之前，已经事先获得您的明确授权及已经充分告知您收集您的个人信息的目的、范围、使用方式。如您无法确认上述内容，我们建议您立即停止访问或使用本平台，并与您的雇主就前述事项进行确认。同时您也可以通过本隐私政策提供的联系方式与我们联系，我们将在我们的能力范围内配合您处理相关事宜。<br>
            <br>通讯录、地理位置、摄像头、麦克风、相册权限，均不会默认开启，只有经过您的明示授权才会在为实现特定功能或服务时使用，您也可以撤回授权。特别需要指出的是，即使经过了您的授权，我们获得了这些敏感权限，也不会在相关功能或服务不需要时收集这些信息。申请操作系统权限列表。</div>
         <div class="text_title">
              一、基本定义<br>
              二、我们在本平台内收集的个人信息<br>
              三、我们如何使用您的个人信息<br>
              四、我们如何使用 Cookie 和同类技术<br>
              五、我们如何共享、转让、公开披露您的个人信息<br>
              六、我们在何处存储您的个人信息<br>
              七、我们如何保护您的个人信息的安全<br>
              八、您的权利<br>
              九、变更<br>
              十、联系方式<br>
              十一、儿童个人信息保护<br>
              十二、其他<br>
         </div>
         <div class="text_Btitle">一、基本定义</div>
         <div class="text_title">
              “个人信息”指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。<br>
            <br>“个人敏感信息”指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，包括身份证件号码、个人生物识别信息、通信记录和内容、财产信息、行踪轨迹、健康生理信息、交易信息等。<br>
            <br>“雇主”指注册、登录、使用本平台并获得管理权限的组织，包括但不限于法人、政府机构、其他组织、合伙或个体工商户等；雇主可以通过获得个人授权的途径替其成员注册企业账户。<br>
            <br>“企业账户”指由雇主邀请并获得其授权同意，作为雇主的成员而使用本平台的账户。<br>
            <br>“关联公司”是指全购网络控制的实体、控制全购网络的实体以及与全购网络共同受控制于同一实体的实体；此处的“控制”是指某实体支配另一实体主要商业行为或活动的权力，这种权力的形成可以是基于股权、投票权以及其他通常认为有支配力或重大影响力的关系。<br>
            <br>“去标识化”是指通过对个人信息的技术处理，使其在不借助额外信息的情况下，无法识别个人信息主体的过程。<br>
            <br>“匿名化”是指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。
         </div>
         <div class="text_Btitle">二、我们在本平台内收集的个人信息</div>
         <div class="text_title">
            在您使用本平台服务的过程中，我们可能收集如下几类个人信息。<br>
            1.个人基本资料：姓名、头像、电话号码、邮箱<br>
            2.个人工作信息：组织名称、部门、职位<br>
            3.网络身份标识信息：账户、IP地址<br>
            4.个人沟通信息：上传、发布、提交或发送的讯息、聊天、文件、日历信息<br>
            5.联系人信息：手机通讯录、好友列表、群列表<br>
            6.个人上网记录：操作日志，包括软件使用记录、搜索记录、点击记录、Cookies<br>
            7.个人常用设备信息：包括硬件设备型号、系统类型、系统版本、设备屏幕尺寸、硬件设备序列号、MAC地址、唯一设备识别码（IMEI、Android ID、IDFA、OpenUDID、SIM卡IMSI信息）、时区、语言设置<br>
            8.个人位置信息：GPS信息、WLAN接入点、蓝牙和基站等传感器信息、行程安排、旅居情况<br>
            9.个人健康生理信息：体温、就诊情况（仅新冠病毒疫情期间）<br>
            <br>请注意，上述所列的信息类型中，存在部分信息若单独使用，如操作日志信息属于无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将有可能被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。
         </div>
         <div class="text_Btitle">三、我们如何使用您的个人信息</div>
         <div class="text_title">
           （一） 平台的基本业务功能需要依赖部分信息才得以正常安全运行，因此需要您向我们提供或允许我们收集如下信息
            <br><br>1、认证功能<br>
            当你使用本平台，您需要提供手机号和/或邮箱，上述信息为使用平台所必需，若您不提供这类信息，你将无法正常使用本平台。当您登录账户后还可以选择是否上传头像以加强个性化展示。但如果您仅需使用浏览、搜索本平台官网展示的全购网络产品、功能及服务介绍，您不需要注册账户并提供上述信息。
            <br><br>2、即时沟通<br>
            为了保证新成员加入群组时可随时随地查看全部历史消息，快速融入团队和项目，提升上手效率，我们将获取您的消息记录存储在云端服务器中，并会自动同步到所有设备。
            在紧急情况下，为了推进消息的快速触达，我们会通过使用您在注册时提供的手机号码向您推送短信或电话，以帮助其他用户提醒您查看信息。
            <br><br>3、日历服务<br>
            您可通过本平台的日历功能添加工作状态（出差中/请假中/会议中）和事件提醒，订阅公共的或同事的日程并发送会议邀请。我们将收集并同步您在平台中处理的日程相关沟通信息，以便在日历功能中展示，帮助您创建管理日历信息、安排任务及提醒您相关日程事项安排，例如当您选择接收、拒绝或待定会议邀请时，该等回复会在日历中标注。如果您是一个日历的控制方，您可将您自己的日历隐私设置为私密、公开或在特定范围中公开。您可订阅其他用户的日历，但前提是其同意您查看其日历。
            <br><br>4、云文档服务<br>
            您可通过本平台的云文档功能，多人同时查看和编辑存储在云盘中的文档，并可添加评论和实时接收[“文档会话”]。为了使参与文档协同的用户清晰明确的知晓文档编辑信息，在您发布文档内容、评论时，我们将收集您发布的信息，并展示您的姓名、头像、发布内容。
            <br><br>5、音视频会议功能<br>
            为了实现在平台内的音视频会议功能，或与机器人实现语音交互。您需要开启麦克风或相机/摄像头权限权限，同时需要请您知晓的是，即使您已同意开启上述权限，我们也仅会在您主动点击客户端内麦克风/摄像头图标时获取语音视频信息。
            <br><br>6、安全监测<br>
            为提高您使用本平台的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规的情况，在您使用本平台时，我们会自动收集您的IP地址、设备唯一标识符、硬件的型号和设置、操作系统及应用程序功能（如MAC地址）以及您对我们服务的详细使用情况，如浏览记录、时区、区域和语言设置、服务器日志（包括访问日期和时间）、应用程序崩溃信息来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
         </div>
         <div class="text_title">
           <br>（二）为了提升服务体验和我们改善服务质量，您可自主选择向我们提供或允许我们收集如下信息<br>
           <br>1、人脸打卡功能
           <br>为进行身份核验，我们可能会收集你主动提供的人脸图像信息，并将您提供的人脸信息进行比对核验。上述信息属于敏感信息，拒绝提供该信息仅会使你无法使用人脸打卡功能，但不影响你正常使用本平台的其他功能。<br>
           <br>2、AI辅助功能
           <br>为了帮助您提高信息获取的效率，我们借助AI技术提供了语音转文字和搜索推荐功能，对此我们需要获取用户的沟通内容及搜索内容。在特定情况下我们会对搜索内容进行技术处理后提供给第三方，由其对搜索推荐功能的优化提供技术支持，您知晓并确认在此情形下第三方是无法将上述信息与任何个人关联的。<br>
           <br>3、地理位置信息
           <br>如您希望在使用日历功能时配有安排会议室的功能，则您需要授权我们获得您的SIM卡、IP地址以及移动装置地址设定的地点信息，以及使用GPS所得的地点信息（如您的移动装置启动该功能）。我们会使用该等信息以向您提供基于地点位置的相关服务。如果您拒绝提供地点信息，我们将不会基于地点位置向您提供相关服务，但不会影响平台其他功能与服务的正常使用。<br>
           <br>4、邀请外部联系人功能
           <br>如果您选择分享口令、邀请链接或二维码邀请外部联系人，我们会调取您的头像、姓名、职位及组织信息并仅在您设备本地的剪贴板上完成口令、链接或二维码的生成，请您注意在使用上述方式邀请外部联系人时会公开您的头像、姓名、职位及组织信息。<br>
           <br>5、服务优化功能
           <br>为向您提供更好、更个性化的服务，例如在不同的服务端或设备上提供更个性化但体验一致的服务，更契合您需求的搜索推荐、客服接待或信息推送，了解产品适配性，我们将收集您的偏好等操作、使用行为信息、地点信息、设备信息。
           <br>上述信息或功能并非平台运行所必需，我们不会强制要求您提供这些信息，您如拒绝不会对您正常使用本平台产生不利影响。
           <br>我们会采取合理可行的措施，尽力避免收集与使用目的无关的个人信息。如果我们使用您的个人信息超出了与使用所声称的目的及具有直接或合理关联的范围，我们将在使用您的个人信息前另外向您告知并征得您的明示同意。
           <br>如我们停止运营本平台服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以提前【60】天以公告的形式通知您，并依照所适用的法律对所持有的您的个人信息进行删除或匿名化处理。
         </div>
         <div class="text_Btitle">四、我们如何使用 Cookie 和同类技术<br>
         </div>
         <div class="text_title">
              我们使用Cookies和其它类似技术（例如web beacons、Flash cookies等）（简称“Cookies”），以提升您使用本平台的体验。当您使用本平台服务时，我们可能会使用相关技术向您的设备发送一个或多个 Cookie 或匿名标识符，以收集和存储您访问、使用本产品时的信息。<br>
            <br>大部分网络浏览器自动接受Cookies，具体是否接受您可以通过设置予以决定。您理解并同意，我们可能存放绝对必要的Cookies即本平台的运行所需要的Cookies，包括让您能够登录本平台安全区域的Cookies。我们存放Cookies将在您终止网站会话或15天后失效。前述存放绝对必要的Cookies行为是本平提供基本服务内容的绝对必要条件，如您不同意相关授权，您无法使用本平台任何服务。
          </div>
         <div class="text_Btitle">五、我们如何共享、转让、公开披露您的个人信息</div>
         <div class="text_title">
           （一）个人信息的共享
           <br>1、我们不会向第三方共享、转让您的个人信息，除非经过您本人事先授权同意，或者共享、转让的个人信息是去标识化处理后的信息，且共享第三方无法重新识别此类信息的个人信息主体。
           <br><br>2、共享您的个人信息，我们将遵循如下原则：
           <br>授权同意原则：未经您的同意，我们不会共享您的个人信息，除非共享的个人信息是去标识化处理后的信息，且共享第三方无法重新识别此类信息的自然人主体。如果第三方使用信息的目的超越原授权同意范围，他们需要重新征得您的同意。
           <br>合法正当与最小必要原则：共享的数据必须具有合法正当目的，且共享的数据以达成目的必要为限。
           <br>安全审慎原则：我们将审慎评估第三方使用共享信息的目的，对这些合作方的安全保障能力进行综合评估，并要求其遵循合作法律协议。我们会对合作方获取信息的软件工具开发包（SDK）、应用程序接口（API）进行严格的安全监测，以保护数据安全。
           <br><br>3、实现功能或服务的共享信息
           <br>如果您选择使用本平台中第三方服务商提供的服务，第三方服务的提供商可能与我们共享您的某些信息。您可以查看有关第三方服务的隐私政策，了解可能与我们共享的数据内容。
           <br>例如，为完成您的平台账户注册，您同意运营商基于您个人的手机号码向我们提供短信验证码，否则无法注册账户。为在平台完成支付服务，保障您的合法权益，防范欺诈、赌博等风险，维护平台及支付相关功能的安全稳定运行，我们需要向提供平台钱包功能的第三方服务商 获取您实际使用的支付账户的用户标识信息。
           <br>1）如您是企业用户员工，您所在企业的企业用户管理员通过本平台选择开通、管理并使用第三方的产品或服务时，将代表您同意我们向该第三方应用服务提供商提供您的个人基础信息（账号、姓名、头像、企业手机号、企业邮箱、部门组织架构）及基础权限，否则您无法使用第三方应用服务。企业用户管理员开通使用第三方应用服务时，需阅读并充分了解并遵守第三方服务的产品功能及隐私保护政策。如您作为个人用户通过本平台选择开通、管理并使用第三方的产品或服务时，您同意我们向该第三方应用服务提供商提供您的个人信息（账号、姓名、头像、企业手机号、企业邮箱、部门组织架构）及基础权限，否则您无法使用第三方应用服务，您开通使用第三方应用服务时，需阅读并充分了解并遵守第三方服务的产品功能及隐私保护政策。
           <br>（2）登录、绑定本平台内产品：经您同意，当您使用指点地球村账号登录第三方的产品或服务时，或将账号与其他第三方账号绑定，我们会将您的基础信息（姓名、头像）及其他您授权的信息与前述产品或服务共享。
           <br>（3）定位服务提供商（高德地图）：我们会将您去标识化的位置信息，交由定位服务提供商（高德地图），用以在日历功能中为您提供更精准的定位。
           <br>（4）关联公司：我们承诺不在任何非必要情况下与任何关联公司共享您的个人信息。例外地必要情形包括：只有共享您的信息，才能提供您需要的服务情况下，但为了更好的给您提供相关服务，我们将使用关联公司所提供的更完善的工具，基于本平台的使用您个人信息的目的，来处理您的个人信息， 以及相关的【功能插件】，来存储、传输、处理您的个人信息。
           <br>（5）其他辅助处理程序支持方：您理解并同意，为保障本平台相关功能的实现与应用安全稳定的运行，我们可能会接入由第三方提供的软件开发包（SDK）实现相关目的。我们会对合作方获取信息的软件工具开发包（SDK）进行严格的安全监测，以保护数据安全。我们对接入的相关第三方SDK在目录中列明。
           <br>我们只会与第三方共享实现目的所必要的信息，您理解并授权此种情况下我们的信息共享行为。如果第三方因业务需要，确需超出前述使用目的而使用个人信息的，该第三方将需再次征求您的同意。
           <br>和第三方合作前，本公司会尽商业上的合理努力对该第三方的安全能力进行检测，评估向其共享相关信息的正当合法必要性，并与第三方签订保密协议，对其查询行为进行技术监测，尽商业上的合理努力督促第三方在使用用户信息时遵守法律法规及协议中约定的保密和安全措施。
           <br><br>（二）个人信息的转让
           <br>随着我们业务的持续发展，我们将有可能进行合并、收购、资产转让，您的个人信息有可能因此而被转移。在发生前述变更时，我们将按照法律法规及不低于本隐私政策所要求的安全标准继续保护或要求个人信息的继受方继续保护您的个人信息，否则我们将要求继受方重新征得您的授权同意。
           <br><br>（三）个人信息的公开披露
           <br>我们不会公开披露您的信息，除非获得您的同意。但根据法律、法规、规章、其他规范性文件、强制性的行政执法或司法要求，在必须提供您个人信息的情况下，我们可能会依据要求的个人信息类型和披露方式向行政执法或司法机构披露您的个人信息。当我们接到披露请求时，在符合法律法规的前提下，我们要求其必须出具与之相应的法律证明文件，我们仅提供执法、司法部门因特定调查目的且有合法权力获取的数据。在法律法规许可的前提下，我们披露的文件均在加密措施的保护之下。
           <br>请您理解，在下列情形中，根据法律法规及国家标准，我们共享、转让、公开披露您的个人信息行为无需征得您的授权同意：
           <br>（1）与国家安全、国防安全直接相关的；
           <br>（2）与公共安全、公共卫生、重大公共利益直接相关的；
           <br>（3）与犯罪侦查、起诉、审判和判决执行等直接相关的；
           <br>（4）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的，但法律法规明确禁止的情形除外；
           <br>（5）您自行向社会公众公开的个人信息；
           <br>（6）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
           <br>根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的处理将无需另行向您通知并征得您的同意。
         </div>
         <div class="text_Btitle">六、我们在何处存储您的个人信息</div>
         <div class="text_title">
           （一）存储地点：<br>
           我们依照法律法规的规定，将在境内运营过程中收集和产生的您的个人信息存储于中华人民共和国境内。目前，我们不会将上述信息传输至境外，如果我们向境外传输，我们将会遵循相关国家规定或者征求您的同意。
           <br><br>（二）存储期限：<br>
           您在使用本平台期间，我们将持续保存您的个人信息，保存期限将以不超过为您提供服务所必须的期间为原则。在您终止使用本平台或关闭相应授权后，除法律法规对于特定信息保留期限另有规定外，我们会对您的信息进行删除或做匿名化处理。如我们停止运营本平台服务，我们将在合理期限内依照所适用的法律对所持有的您的个人信息进行删除或匿名化处理。
         </div>
         <div class="text_Btitle">七、我们如何保护您的个人信息的安全</div>
         <div class="text_title">
           （一）我们非常重视您个人信息的安全。 建立了数据安全制度规范和实施了安全技术措施，防止您的个人信息遭到未经授权的访问、修改，避免数据的损坏或丢失。我们的网络服务采取了传输层安全协议等加密技术，确保您的数据在网络传输过程的安全。
           <br><br>（二）我们采用严格的数据处理权限控制，避免数据被违规使用；我们采用打码屏蔽等去标识化技术的多种数据脱敏方式增强个人信息在使用中的安全性；我们采用行业广泛应用的加密技术对您的个人信息进行加密保存，并通过数据隔离技术进行隔离。例如，我们对存储在我们服务器上以及您终端设备上的所有讯息进行加密处理。我们始终落实该等技术措施和组织管理方式，并可能不时加以修订完善以提升系统的整体安全性。
           <br><br>（三）尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我们将尽力确保您提供给我们的个人信息的安全性。请您知悉并理解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。因此，我们强烈建议您采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的账号密码等个人信息透露给他人。
           <br><br>（四）在发生危害网络安全的事件时，我们会按照网络安全事件应急预案，及时采取相应的补救措施。如果我们的物理设施或技术防护措施遭到破坏，导致您的个人信息被泄露、非法提供或滥用，造成您的合法权益受损，我们将严格依照法律的规定承担相应的责任。
           <br><br>（五）在我们获悉发生了您的个人信息不幸被泄露、非法提供或滥用的个人信息安全事件后，我们将按照法律法规的要求在最迟不低于【10】天内及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以平台内推送通知的方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
         </div>
         <div class="text_Btitle">八、您的权利</div>
         <div class="text_title">
           （一）如何访问、更正您的个人信息
          <br>1、访问个人信息：访问头像、姓名、工作状态、部门、直属上级、邮箱、手机号。进入【“指点地球村或全购任务”个人中心】访问个人信息。
          <br>2、如您是企业用户员工，您可以向您的雇主提交更正信息申请，由企业用户管理员处理。
          <br><br>（二）删除您的信息
          <br>您可以通过上述“（一）访问、更正您的信息”中列明的方式删除您的部分信息。在以下情形中，您可以向我们提出删除个人信息的请求：
          <br>1、如果我们处理个人信息的行为违反法律法规；
          <br>2、如果我们收集、使用您的个人信息，却未征得您的明确同意；
          <br>3、如果我们处理个人信息的行为严重违反了与您的约定；
          <br>4、如果您不再使用我们的产品或服务，或您主动注销了账号；
          <br>5、如果我们永久不再为您提供产品或服务。
          <br>若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，并要求其及时删除（除非法律法规另有规定，或这些主体已独立获得您的授权）。
          <br>当您或我们协助您删除相关信息后，因为适用的法律和安全技术，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并将其与任何进一步处理隔离，直到备份可以清除或实现匿名。
          <br><br>（三）撤销授权同意的方法
          <br>除为了平台正常运营所必需提供的信息外，您可以操作改变您的授权同意范围：
          <br>您可以在登录手机客户端，在手机端点击个人头像中选择系统设置-隐私设置或改变您的智能移动设备的设置等方式给予或收回您的授权同意（例如，您可以在手机客户端“系统设置-隐私设置”中收回您“通过手机号码搜索找到我”的用户同意授权）。
          <br>当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
          <br><br>（四）如何注销您的账号
          <br>如您是企业用户员工，您可以向您的雇主提交申请删除您的个人账号，由企业用户管理员处理。如您是个人用户，可以在“在手机端点击个人头像，选择系统设置- 账号与安全- 注销账号”在线提交注销申请。
         </div>
         <div class="text_Btitle">九、变更</div>
         <div class="text_title">本隐私政策一旦发生任何重大变更，我们将尽合理努力向所有用户广而告之，例如通过在本平台上发布通知；但您应定期查看本隐私政策，以查看有关变更。我们还会更新本隐私政策顶部的“更新日期”和“生效日期”。您在本隐私政策更新之后继续访问或使用本平台，即视为您接受更新后的隐私政策。如果您不同意更新后的隐私政策，请您停止访问或使用本平台。</div>
         <div class="text_Btitle">十、联系方式</div>
         <div class="text_title">
           与本政策相关的任何疑问、意见或请求，请发送至 kaifa@i2f2f.com。一般情况下，我们会在收到您相关联系信息并核实您身份后的【15】日内回复。
           <br><br>对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将酌情收取一定费用。对于与您的身份不直接关联的信息、无端重复信息，或者需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者不切实际的请求，我们可能会予以拒绝。
         </div>
         <div class="text_Btitle">十一、儿童个人信息保护</div>
         <div class="text_title">
            若您是未满14周岁的儿童，在使用本平台服务前，应在你的父母或其他监护人监护、指导下共同阅读并同意本隐私政策。
            <br><br>我们根据国家相关法律法规的规定保护儿童的个人信息，只会在法律允许、父母或其他监护人明确同意或保护儿童所必要的情况下收集、使用、储存、共享、转让或披露儿童的个人信息；如果我们发现在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会设法尽快删除相关信息。
            <br><br>若您是儿童的监护人，当您对您所监护的儿童个人信息有相关疑问时，请通过本隐私政策公示的联系方式与我们联系。
         </div>
         <div class="text_Btitle">十二、其他</div>
         <div class="text_title">
          （一）本隐私政策仅适用于您在中国大陆（仅为本条款之目的，不含香港、澳门、台湾地区）境内使用本平台服务的情形。本隐私政策可能存在多种语言版本，如各语言版本条款出现不一致或冲突，以中文版本为准。
          <br><br>（二）本隐私政策中的标题仅为方便及阅读而设，并不影响本隐私政策中任何规定的含义或解释。
         </div>
      </div>
    </div>
    <div class="button"
     :style="{'backgroundColor': buttonType ? '#3974FB' : '#A7BFF5'}"
     @click="select()"
    >{{buttonContent}}</div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'

export default {
  name: 'read',
  methods: {
    back () {
      this.$router.back()
    }
  },
  setup () {
    const router = useRouter()
    const scrollRef = ref(null)
    const buttonContent = ref('请上滑看完本条款再同意')
    const buttonType = ref(false)
    function onScroll () {
      var scrollTop = scrollRef.value.scrollTop
      var clientHeight = scrollRef.value.clientHeight
      var scrollHeight = scrollRef.value.scrollHeight
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        buttonContent.value = '同意以上条款'
        buttonType.value = true
      }
    }
    function select () {
      if (buttonType.value) {
        router.push({
          path: '/newuser'
        })
      }
    }
    return { scrollRef, onScroll, buttonContent, buttonType, select }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.mock {
  height: 1.39rem;
  background-color: #7f7f7f;
}
.title {
  line-height: 0.45rem;
  padding-left: 0.21rem;
  display: flex;
  border-bottom: 0.01rem solid #D6D6D6;
  box-sizing: border-box;
  .text{
    margin-right: 1.92rem;
    font-family: PingFangSC-Medium;
    font-size: 0.15rem;
  }
  .icon {
    width: 0.12rem;
  }
}
.content {
  margin: 0.16rem 0.21rem 0.175rem 0.21rem;
  .content_title {
    font-family: PingFangSC-Medium;
    font-size: 0.15rem;
    text-align: center;
    margin-bottom: 0.16rem;
  }
  .content_text {
    font-family: PingFangSC-Medium;
    font-size: 0.15rem;
    width: 3.33rem;
    height: 3.57rem;
    word-wrap: break-word;
    overflow: scroll;
    .text_Btitle {
      margin: 0.15rem 0;
      font-size: 0.2rem;
    }
  }
}
.button {
  width: 3.27rem;
  line-height: 0.4rem;
  font-size: 0.14rem;
  font-family: PingFangSC-Medium;
  text-align: center;
  margin: 0 0.24rem 0.15rem 0.24rem;
  color: #ffffff;
}
</style>
